

























































































































































































































































































































































































































import Vue from 'vue'
import ActionFeedbackDisplay from '@/components/ActionFeedbackDisplay.vue'
import { mapActions, mapGetters } from 'vuex'
import { ButtonObject } from '@/models/interfaces'

export default Vue.extend({
  name: 'ButtonGenerator',
  components: {
    ActionFeedbackDisplay,
  },
  data: (): {
    button: ButtonObject
    buttonDefault: ButtonObject | null
    fonts: string[]
    rules: {
      text: { (v: string): boolean | string }[]
    }
    isValid: boolean
    displayCode: boolean
    popout: boolean
  } => {
    return {
      buttonDefault: null,
      button: {
        sourceCode: '',
        forEmail: false,
        forOutlook: false,
        forActBlue: false,
        actBlue: {
          amounts: '5,10,25,50',
          text: 'Donate $AMOUNT right now',
          other: true,
          otherText: 'Donate another amount',
          url: '',
          expressLane: false,
          expressLaneDisclaimer:
            'If you’ve saved your payment info with ActBlue, your contribution will be processed immediately.',
          expressLaneDisclaimerStyle:
            'color: #666;font-size: 13px;font-style: italic;text-align: center;width: 100%;',
        },
        buttonStack: [
          {
            text: 'Get help with your political tech',
            url: 'https://notaproblemforlong.com',
          },
        ],
        color: '#F44336',
        color2: '#9C27B0',
        gradient: false,
        border: false,
        borderColor: '#2196F3',
        borderWidth: '0',
        borderStyle: 'solid',
        fontSize: '18',
        height: '50',
        width: '310',
        radius: '7',
        spacing: '10',
        columns: '1',
        fontFamily: 'Arial, Helvetica, sans-serif',
        bold: true,
        center: false,
      },
      fonts: [
        'Arial, Helvetica, sans-serif',
        'Tahoma, Geneva, sans-serif',
        '"Trebuchet MS", Helvetica, sans-serif',
        'Verdana, Geneva, sans-serif',
        'Georgia, serif',
        '"Times New Roman", Times, serif',
        '"Courier New", Courier, monospace',
        'Impact, Charcoal, sans-serif',
      ],
      rules: {
        text: [(v: string) => !!v || 'Field is required'],
      },
      isValid: true,
      displayCode: false,
      popout: true,
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getWorkingButton']),
    containerWidth(): number {
      return (
        (parseInt(this.button.width) + parseInt(this.button.spacing) * 2) *
        parseInt(this.button.columns)
      )
    },
    buttonCount(): number {
      if (this.button.buttonStack) {
        return this.button.buttonStack.length
      } else {
        return 1
      }
    },
    form(): Vue & { validate: () => boolean } {
      return this.$refs.ButtonGenerator as Vue & { validate: () => boolean }
    },
    buttonStyle(): string {
      return (
        'display: inline-block;' +
        'color: #ffffff;' +
        'text-align: center;' +
        'text-decoration: none;' +
        'font-weight: ' +
        (this.button.bold ? 'bold' : 'normal') +
        ';' +
        'font-family: ' +
        this.button.fontFamily +
        ';' +
        'line-height: ' +
        this.button.height +
        'px;' +
        'width: ' +
        this.button.width +
        'px;' +
        'background-color: ' +
        this.button.color +
        ';' +
        'font-size: ' +
        this.button.fontSize +
        'px;' +
        'border-radius: ' +
        this.button.radius +
        'px;' +
        (this.button.border
          ? '\
						border-color: ' +
            this.button.borderColor +
            ';\
						border-width: ' +
            this.button.borderWidth +
            'px;\
						border-style: ' +
            this.button.borderStyle +
            ';\
					'
          : '') +
        (this.button.gradient
          ? '\
						background-image: -moz-linear-gradient(top, ' +
            this.button.color +
            ' 0%, ' +
            this.button.color2 +
            ' 100%);\
						background-image: -webkit-gradient(left top, left bottom, color-stop(0%, ' +
            this.button.color +
            '), color-stop(100%, ' +
            this.button.color2 +
            '));\
						background-image: -webkit-linear-gradient(top, ' +
            this.button.color +
            ' 0%, ' +
            this.button.color2 +
            ' 100%);\
						background-image: -o-linear-gradient(top, ' +
            this.button.color +
            ' 0%, ' +
            this.button.color2 +
            ' 100%);\
						background-image: -ms-linear-gradient(top, ' +
            this.button.color +
            ' 0%, ' +
            this.button.color2 +
            ' 100%);\
						background-image: linear-gradient(to bottom, ' +
            this.button.color +
            ' 0%, ' +
            this.button.color2 +
            " 100%);\
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='" +
            this.button.color +
            "', endColorstr='" +
            this.button.color2 +
            "', GradientType=0 );\
					"
          : '')
      )
    },
    // need annotation due to `this` in return type: https://vuejs.org/v2/guide/typescript.html#Annotating-Return-Types
    buttonHtml(): string {
      //email button html derived from https://buttons.cm/
      //docs about mso stroke: https://docs.microsoft.com/en-us/windows/win32/vml/web-workshop---how-to-use-vml-on-web-pages-----stroke--element
      if (this.button.forEmail) {
        let code =
          '<table border="0" cellspacing="0" cellpadding="0" style="display: inline-block;">'
        if (this.button.center) {
          code =
            '<table border="0" cellspacing="0" cellpadding="0" width="100%">' +
            '<tr><td style="text-align: center;" width="100%" align="center"><center>' +
            code +
            '<tbody style="text-align: center">'
        }
        let columns = parseInt(this.button.columns)
        let counter = 1
        if (this.button.forActBlue && this.button.actBlue.expressLane) {
          code +=
            '<tr><td align="center" style="text-align: center;" colspan="' +
            columns +
            '">\
							<span style="' +
            this.button.actBlue.expressLaneDisclaimerStyle +
            '">' +
            this.button.actBlue.expressLaneDisclaimer +
            '</span>\
						</td></tr>'
        }
        this.button.buttonStack.forEach((button) => {
          if (counter === 1) {
            code += '<tr>'
          }
          code +=
            '<td style="display: inline-block;">' +
            '<table cellspacing="' +
            parseInt(this.button.spacing) +
            '"><tr><td>'
          if (this.button.forOutlook) {
            code +=
              '<!--[if mso]>' +
              '<v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" \
							href="' +
              button.url +
              '" \
							style="height:' +
              this.button.height +
              'px;width:' +
              this.button.width +
              'px;v-text-anchor:middle;" \
							arcsize="' +
              Math.round(Number(this.button.radius) * 2.5) +
              '%" \
							fillcolor="' +
              this.button.color +
              '"' +
              (this.button.border
                ? ' strokecolor="' +
                  this.button.borderColor +
                  '" strokeweight="' +
                  this.button.borderWidth +
                  'px"'
                : ' stroke="f"') +
              '>' +
              (this.button.gradient
                ? '<v:fill color2="' +
                  this.button.color2 +
                  '" type="gradient" />'
                : '') +
              '<w:anchorlock/>' +
              '<center>' +
              '<![endif]-->'
          } // end forOutlook opening tags
          code +=
            '<a href="' +
            button.url +
            '" style="' +
            this.buttonStyle +
            'text-decoration:none;-webkit-text-size-adjust:none;">' +
            button.text +
            '</a>'
          if (this.button.forOutlook) {
            code +=
              '<!--[if mso]>' + '</center>' + '</v:roundrect>' + '<![endif]-->'
          } // end forOutlook closing tags
          code += '</td></tr></table>' + '</td>'
          if (counter === columns) {
            code += '</tr>'
            counter = 1 //reset counter
          } else {
            counter++
          }
        })
        code += '</table>'
        if (this.button.center) {
          code += '</center></td></tr></table>'
        }
        return code
      } else {
        let code =
          '<div style="display: flex;flex-wrap: wrap;' +
          (this.button.center
            ? 'align-items: center;justify-content: center;'
            : '') +
          'max-width: ' +
          this.containerWidth +
          'px;">'
        if (this.button.forActBlue && this.button.actBlue.expressLane) {
          code +=
            '<span style="' +
            this.button.actBlue.expressLaneDisclaimerStyle +
            '">' +
            this.button.actBlue.expressLaneDisclaimer +
            '</span>'
        }
        this.button.buttonStack.forEach((button) => {
          code +=
            '<div class="recount-button-generation" style="margin: ' +
            this.button.spacing +
            'px;">' +
            '<a href="' +
            button.url +
            '" style="' +
            this.buttonStyle +
            '">' +
            button.text +
            '</a></div>'
        })
        code += '</div>'
        return code
      }
    },
  },
  methods: {
    ...mapActions([
      'addButton',
      'updateWorkingButton',
      'updateActionFeedback',
      'resetActionFeedback',
    ]),
    addButtonToStackClick() {
      this.button.buttonStack.push({ text: '', url: '' })
    },
    removeButtonFromStackClick(buttonIndex: number) {
      this.button.buttonStack.splice(buttonIndex, 1)
    },
    popClick() {
      this.popout = !this.popout
      this.$vuetify.goTo(9999, {})
    },
    configureForActBlue() {
      if (!this.button.forActBlue) {
        return
      }
      this.button.buttonStack = []
      const amounts = this.button.actBlue.amounts.replace(' ', '')
      const amountsArray = amounts.split(',')

      //build actblue url
      const url =
        this.button.actBlue.url +
        '?refcode=' +
        this.button.sourceCode +
        '&amounts=' +
        amounts

      //build new button stack
      amountsArray.forEach((amount) => {
        let amountUrl = url + '&amount=' + amount
        if (this.button.actBlue.expressLane) {
          amountUrl += '&express_lane=true'
        }
        let amountText = this.button.actBlue.text.replace('AMOUNT', amount)
        this.button.buttonStack.push({ text: amountText, url: amountUrl })
      })
      if (this.button.actBlue.other) {
        this.button.buttonStack.push({
          text: this.button.actBlue.otherText,
          url: url,
        })
      }
    },
    copyButtonToClipboard() {
      this.validateForm('copyCodeToClipboard')
      if (this.isValid) {
        const range = document.createRange()
        const htmlCode = document.getElementById('htmlcode')
        if (htmlCode) {
          range.selectNode(htmlCode)
        }
        const selection = window.getSelection()
        if (selection != null) {
          selection.removeAllRanges()
          selection.addRange(range)
        }
        document.execCommand('copy')
      }
    },
    displayCodeClick() {
      this.displayCode = !this.displayCode
    },
    saveButtonClick() {
      this.validateForm('addButton')
      if (this.isValid) {
        if (this.getUser) {
          this.addButton({ button: this.button })
        } else {
          this.updateWorkingButton(this.button)
          this.$router.push('login')
        }
      }
    },
    validateForm(action: string) {
      this.resetActionFeedback(action)
      this.form.validate()
      if (this.isValid) {
        return true
      } else {
        this.updateActionFeedback({
          type: action,
          result: {
            state: 'error',
            messages: ['Oops, errors detected! Check form and try again.'],
          },
        })
        return false
      }
    },
    resetFormClick() {
      this.button = { ...this.buttonDefault } as ButtonObject // make a copy
      // if (this.$refs.ButtonGenerator instanceof HTMLFormElement) {
      // ;(this.$refs.ButtonGenerator as HTMLFormElement).reset()
      // }
    },
  },
  created() {
    if (Object.keys(this.getWorkingButton).length) {
      this.button = this.getWorkingButton
    }
    this.buttonDefault = { ...this.button } // make a copy
  },
})
