<template>
  <v-row align="stretch" align-content="start" justify="center" no-gutters>
    <v-col>
      <ButtonGenerator />
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import ButtonGenerator from '@/components/ButtonGenerator.vue'

export default {
  name: 'ButtonMaker',
  components: {
    ButtonGenerator,
  },
}
</script>
